import React from "react";
import { Layout, Hero, Nav } from "src/components";
import { Typography, Container } from "@material-ui/core";

const NotFoundPage = () => {
  return (
    <Layout>
      <Hero size="large" color="#000">
        <Hero.Header>
          <Nav />
        </Hero.Header>

        <Hero.Body>
          <Container>
            <Typography variant="h1" align="center">
              <strong>Page Not Found</strong>
            </Typography>
          </Container>
        </Hero.Body>
      </Hero>
    </Layout>
  );
};

export default NotFoundPage;
